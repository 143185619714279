<template>
  <div class="builders">
    <Header
      :details="{
        title: 'Builders',
        back: { name: 'builders' },
        actions: false,
      }"
    />
    <div class="d-flex">
      <div class="col-8 left-side">
        <div class="box-container">
          <div class="input-box">
            <input name="name" type="text" :placeholder="$t('properties.title.placeholder')" v-model="builder.name" />
          </div>
          <header class="lang d-flex justify-content-end">
            <Select class="lang-select" v-model="currentLanguage" :options="$store.state.global.availableLanguages" />
          </header>
          <template v-for="(lang, index) in $store.state.global.availableLanguages">
            <template v-if="lang.value === currentLanguage">
              <div class="input-box" :key="'descr-' + index">
                <Textarea
                  name="description"
                  textareaClass="editor"
                  is-counter="true"
                  max="400"
                  v-model="translations[index].description"
                />
              </div>
            </template>
          </template>
        </div>
        <div class="property-list-container ">
          <h2>{{ $t("builders.projects_list") }}</h2>
          <div class="table-list" v-if="projects && projects.length">
            <ul class="labels d-flex">
              <li>
                <button>{{ $t("overall.name") }}</button>
              </li>
              <li>
                <button>{{ $t("End Date") }}</button>
              </li>
              <li>
                <button>{{ $t("overall.location") }}</button>
              </li>
            </ul>
            <div class="elements">
              <template>
                <div class="line" v-for="p in projects" :key="p.token">
                  <router-link :to="{ name: 'edit-project', params: { token: p.token } }">
                    <div>{{ p.name ? p.name : "-" }}</div>
                    <div>{{ p.end_date ? parseBeautifulDate(p.end_date) : "-" }}</div>
                    <div>{{ p.address ? p.address.city : "-" }}</div>
                  </router-link>
                </div>
              </template>
              <span class="no-element" v-if="projects.length < 1 && !loading">{{ $t("projects.no_project") }}</span>
            </div>
          </div>
          <p v-else>{{ $t("builders.no_project") }}</p>
        </div>
      </div>
      <div style="margin-top: 0" class="col-4 right-side">
        <div class="box-container">
          <div class="box-thumbnail">
            <label for="thumbnail" class="add-thumbnail change-image" v-if="!builder.image && !tempImage">
              <span class="d-flex align-items-center justify-content-center"
                ><em><thumbnailSVG />{{ $t("properties.library.add_picture") }}</em></span
              >
            </label>
            <label for="thumbnail" v-else>
              <img :src="tempImage ? tempImage : builder.image" alt="Thumbnail" />
            </label>
            <input class="hide" ref="image" type="file" @change="previewFiles" id="thumbnail" />
          </div>
          <div class="box">
            <label>{{ $t("overall.phone") }}</label>
            <div>
              <div class="d-flex email-handler" v-for="(phone, i) in phones" :key="'phone' + i">
                <input name="email" type="text" v-model="phone.value" :placeholder="$t('builders.enter_phone')" />
                <button class="delete" @click="removePhone(phone.value)"><crossSVG /></button>
              </div>
              <button class="colored" @click="addPhone">+ {{ $t("builders.add_phone") }}</button>
            </div>
          </div>
          <div class="box">
            <label>{{ $t("overall.email") }}</label>
            <div>
              <div class="d-flex email-handler" v-for="(email, i) in emails" :key="'email' + i">
                <input name="email" type="text" v-model="email.value" :placeholder="$t('builders.enter_email')" />
                <button class="delete" @click="removeEmail(email.value)"><crossSVG /></button>
              </div>
              <button class="colored" @click="addEmail">+ {{ $t("builders.add_email") }}</button>
            </div>
          </div>
          <div class="box">
            <label>{{ $t("overall.website") }}</label>
            <input name="website" type="text" v-model="builder.website" />
          </div>
          <div class="box">
            <label>{{ $t("overall.language") }}</label>
            <input name="language" type="text" v-model="builder.language" />
          </div>
          <div>
            <label>{{ $t("overall.address") }}</label>
            <AddressAutocomplete v-model="address" />
          </div>
        </div>
      </div>
    </div>
    <div class="actions-bar">
      <template v-if="!loading">
        <button class="cancel">{{ $t("overall.cancel") }}</button>
        <button class="cta save fixed-button" @click="save">
          <span class="txt">{{ $t("overall.save") }}</span>
        </button>
      </template>
      <span class="loader" v-else></span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import modalBus from "@/bus/modal-bus";
import notifBus from "@/bus/notification-bus";

export default Vue.extend({
  name: "EditBuilders",
  data() {
    return {
      builder: {
        id: null,
        image: null,
        name: null,
        address: null,
        phone: null,
        email: null,
        language: null,
        website: null,
      },
      tempImage: null,
      emails: [],
      phones: [],
      address: {},
      translations: [],
      loading: false,
      currentLanguage: this.$cookies.get("usr_lang"),
      projects: [],
    };
  },
  methods: {
    deleteMethod() {
      this.$axios.delete("api/builders/" + this.builder.token).then((res) => {
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("projects.notifications.deleted"),
        });
        this.$router.push({ name: "builders" });
      });
    },
    save() {
      this.loading = true;
      const formData = new FormData();
      if (this.builder.image && this.builder.image.length > 400) {
        this.builder.image = null;
      }
      if (this.$refs.image.files[0]) formData.append("image", this.$refs.image.files[0]);

      formData.set("builder", JSON.stringify(this.builder));
      formData.set("address", JSON.stringify(this.address));
      formData.set("emails", JSON.stringify(this.emails));
      formData.set("phones", JSON.stringify(this.phones));
      formData.set("translations", JSON.stringify(this.translations));

      this.$axios.post("api/builders", formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
        this.loading = false;
        const message = this.builder.id
          ? this.$t("builders.notifications.updated")
          : this.$t("builders.notifications.created");
        notifBus.$emit("show", {
          status: "success",
          message,
        });
        this.$router.push({ name: "builders" });
      });
    },
    addEmail() {
      this.emails.push({ value: "" });
    },
    removeEmail(toDelete) {
      this.emails = this.emails.filter((el) => el.value !== toDelete);
    },

    addPhone() {
      this.phones.push({ value: "" });
    },
    removePhone(toDelete) {
      this.phones = this.phones.filter((el) => el.value !== toDelete);
    },
    previewFiles(event) {
      let reader = new FileReader();
      let _this = this;
      reader.onload = function() {
        _this.tempImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
  },
  watch: {
    "$store.state.global.availableLanguages": {
      immediate: true,
      handler(el) {
        this.translations = this._global.availableLanguages.map((el) => {
          return {
            lang: el.value,
            title: "",
            description: "",
            slug: "",
          };
        });
      },
    },
  },
  created() {
    if (this.$route.params.token !== undefined) {
      this.$axios.get("api/builders/" + this.$route.params.token).then((res) => {
        let builder = res.data;
        this.phones = res.data.phones.map((el) => {
          return { value: el.element };
        });

        this.emails = res.data.emails.map((el) => {
          return { value: el.element };
        });
        this.translations = this._global.availableLanguages.map((l) => {
          const index = builder.translations.findIndex((t) => t.lang === l.value);
          if (index !== -1) {
            const temp = { ...builder.translations[index] };
            builder.translations.splice(index, 1);
            return temp;
          }

          return {
            lang: l.value,
            title: "",
            description: "",
            slug: "",
          };
        });
        this.builder = res.data.builder;
        this.address = res.data.address;
        this.projects = res.data.projects;
        console.log(this.projects);
      });
    }
  },
});
</script>

<style lang="scss">
.builders {
  .labels li {
    flex-basis: 33.333%;
    width: 33.333%;
  }

  .elements .line div {
    flex-basis: 33.333%;
    width: 33.333%;
  }
}
</style>
